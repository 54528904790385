
/* ****************************** */
/* OVERRIDE SOME BOOTSTRAP STYLES */
/* ****************************** */
.container {
    width: 95%;
}

/* Move body below fixed navigation bar */
.body-content {
    margin-top: 125px;
}

/* Set colors on navigation bar */
.nav li {
    cursor: pointer;
}

.navbar-inverse .navbar-nav > li > a {
    color: white;
}

    .navbar-inverse .navbar-nav > li > a:hover {
        color: lightgray;
    }

.navbar-inverse .navbar-brand {
    color: black;
}

    .navbar-inverse .navbar-brand:hover {
        color: lightgray;
    }

.navbar-fixed-top {
    font-size: 18px;
    color: #000;
    min-height: 93px;
}

.navbar-nav > li > a {
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 93px;
}

@media (max-width: 767px) {
    .navbar-nav > li > a {
        line-height: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}


/* Responsive: Portrait tablets and up */
@media screen and (min-width: 768px) {
    .jumbotron {
        margin-top: 20px;
    }

    .body-content {
        padding: 0;
    }
}

/* Places the pagination closer to the table */
.pagination {
    margin-top: -1em;
}

.configurator-checkbox-group {
    border: solid 1px #ccc;
    border-radius: 3px;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 12px;
    margin-bottom: 12px;
    box-sizing: border-box;
}

.sizer-inline-textbox {
    float: left;
    width: 60%;
}

.sizer-inline-select {
    float: left;
    width: 40%;
}

.sizer-total-textbox {
    margin-top: -5px;
}

.group-box {
    margin-bottom: 0px;
}

.group-heading
{
    padding-top: 3px;
    padding-bottom: 3px;
}

.group-form-group {
    margin-bottom: 0px;
}

.substance-select {
    width: 88.99%;
    float: left;
}

.btn-substance {
    margin-left: 3px;
}
